<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
<Loader v-model="loading" />
        <Panel header="Mesero">
          <div class="formgrid grid p-fluid">
            <div class="col-12">
              <FormToolbar :actions="['new','save','delete']" @new="newWaiter" @save="saveWaiter" @delete="deleteWaiter" />
            </div>
            <FormInputText wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="waiter.name" />
            <FormInputText wrapperClass="field col-12 xl:col-4" label="Apellido Paterno" v-model="waiter.lastname" />
            <FormInputText wrapperClass="field col-12 xl:col-4" label="Apellido Materno" v-model="waiter.lastname2" />
            <FormInputSwitch wrapperClass="field col-12 xl:col-3" label="Cajero" v-model="waiter.is_chasher" /> 
            <FormInputSwitch wrapperClass="field col-12 xl:col-3" label="Permisos de Cancelacion" v-model="waiter.have_cancel" /> 
            <FormInputSwitch wrapperClass="field col-12 xl:col-3" label="Permisos de Descuento" v-model="waiter.have_discount" /> 
            <FormInputSwitch wrapperClass="field col-12 xl:col-3" label="Activo" v-model="waiter.is_active" /> 
          </div>
        </Panel>
    </div>
    <div class="col-12">
         <BasicDatatable :rows="waiters" :selectionMode="'single'" :headers="headers" @selected="selectWaiter"/>
    </div>
  </div>
</template>
<script>
import { waiter } from "../../../models/waiter";
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputSwitch from "../../../components/general/FormInputSwitch.vue";
import formMixin from "../../../mixins/form.js";
import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from '../../../utilities/General';
export default {
  expose: ["refresh"],
  mixins: [formMixin],
  components: { Loader, BasicDatatable, FormToolbar, FormInputText, FormInputSwitch},
  data() { 
    return {
      loading: false,
      waiter: new waiter(),
      waiters: [],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Apellido", "lastname")
      ]
    };
  },
  methods: {
    selectWaiter(entity) {
      this.waiter = fillObject(this.waiter, entity);
    },
    newWaiter() {
      this.waiter = new waiter(this.session);
    },
    async saveWaiter() {
      this.loading = true;
      try {
        var newWaiter = await this.waiter.save();
        this.waiters.push(newWaiter);
        this.newWaiter();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      this.waiter = new waiter(this.session);
      try {
        this.waiters = await this.waiter.all();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>

