import axios from "axios";

export class table
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.area = null;
        this.id_number = null;
        this.name = null;
        this.key_name = null;
        this.created_by = null;
        this.created = null;
        this.modified_by = null;
        this.modified = null;
        this.is_active = null;
        this.terminals = null;
    }

    async all() {
        let response = await axios.get("tpv/table", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async save() {
        let response = await axios.post("tpv/table/save", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}