<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
   <Loader v-model="loading" />
        <Panel header="Mesa">
          <div class="formgrid grid p-fluid">
            <div class="col-12">
                <FormToolbar :actions="['new','save','delete']" @new="newTable" @save="saveTable" @delete="deleteTable" />
            </div>
            <FormInputText wrapperClass="field col-12 xl:col-4" label="Clave" v-model="table.key_name" />
            <FormInputText wrapperClass="field col-12 xl:col-8" label="Nombre" v-model="table.name" />
          </div>
        </Panel>
    </div>
    <div class="col-12">
        <BasicDatatable :rows="tables" :selectionMode="'single'" :headers="headers" @selected="selectTable"/>
    </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import formMixin from '../../../mixins/form.js'
import { table } from "../../../models/table";
import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from '../../../utilities/General';
export default {
  mixins: [formMixin],
  expose: ["refresh"],
  components: { FormInputText, FormToolbar, Loader, BasicDatatable },
  data() {
    return {
      loading: false,
      table: new table(),
      tables: [],
      headers: [
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Nombre", "name")
      ]
    };
  },
  methods: {
    selectTable(entity) {
      this.table = fillObject(this.table, entity);
    },
    newTable() {
      this.table = new table(this.session);
    },
    async saveTable() {
      this.loading = true;
      try {
        var newTable = await this.table.save();
        this.tables.push(newTable);
        this.newTable();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
    this.loading = true;
    this.table = new table(this.session);
    try {
      this.tables = await this.table.all();
    } catch (error) {
      this.showError(error);
    } finally {
      this.loading = false;
    }
  },
  },

};
</script>

<style>
</style>

