<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
        <Loader v-model="loading" />
        <Panel header="Tipo Pago">
          <div class="formgrid grid p-fluid">
            <div class="col-12">
              <FormToolbar :actions="['new','save','delete']" @new="newPaymentType" @save="savePaymentType" @delete="deletePaymentType" />
            </div>
            <FormInputText wrapperClass="field col-12 xl:col-6" label="Nombre" v-model="payment_type.name" />
            <FormInputText wrapperClass="field col-12 xl:col-2" label="Codigo SAT" v-model="payment_type.sat_code" />
            <FormDropdown wrapperClass="field col-12 xl:col-2" label="Tipo" v-model="payment_type.type" :options="[
                  { label: 'EFECTIVO', value: 'CASH' },
                  { label: 'TARJETA', value: 'CARD' },
                  { label: 'OTRO', value: 'OTHER' },
                ]" optionLabel="label" optionValue="value"/>
            <FormInputSwitch wrapperClass="field col-12 xl:col-2" label="Activo" v-model="payment_type.is_active" />
          </div>
        </Panel>
    </div>
    <div class="col-12">
 <BasicDatatable
          :rows="payment_types"
          :selectionMode="'single'"
          :headers="headers"
          @selected="selectPaymentType"
        />
    </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import FormInputSwitch from "../../../components/general/FormInputSwitch.vue";

import { payment_type } from "../../../models/payment_type";
import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from "../../../utilities/General";
import formMixin from "../../../mixins/form.js";

export default {
  expose: ["refresh"],
  mixins: [ formMixin ],
  components: { FormDropdown, FormInputSwitch, FormToolbar, FormInputText, Loader, BasicDatatable },
  data() {
    return {
      loading: false,
      payment_type: new payment_type(),
      payment_types: [],
      headers: [
        new HeaderGrid("Nombre", "name")
      ],
    };
  },
  methods: {
    selectPaymentType(entity) {
      this.payment_type = fillObject(this.payment_type, entity);
    },
    newPaymentType() {
      this.payment_type = new payment_type(this.session);
    },
    async savePaymentType() {
      this.loading = true;
      try {
        var newPaymentType = await this.payment_type.save();
        this.payment_types.push(newPaymentType);
        this.newPaymentType();
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      this.payment_type = new payment_type(this.session);
      try {
        this.payment_types = await this.payment_type.all();
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
  },
  },
  
};
</script>

<style>
</style>

