<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
     <Loader v-model="loading" />
        <Panel header="Configuracion de Terminal">
          <TabView @tab-change="onTabChange" :activeIndex="tab">
            <TabPanel header="Mesa">
              <MesaUI  ref="mesa"/>
            </TabPanel>
            <TabPanel header="Mesero">
                <MeseroUI ref="mesero" />
            </TabPanel>
            <TabPanel header="Tipo Pago">
                <TipoPagoUI ref="tipopago" />
            </TabPanel>
            <TabPanel header="Area de Entrega">
                <AreaEntregaUI ref="areaentrega" />
            </TabPanel>
        </TabView>
      </Panel>
    </div>
  </div>
</template>
<script>
import MesaUI from "./Terminal/Mesa.vue";
import MeseroUI from "./Terminal/Mesero.vue";
import TipoPagoUI from "./Terminal/TipoPago.vue";
import AreaEntregaUI from "./Terminal/AreaEntrega.vue";


import Loader from "../../components/general/Loader.vue";
export default {
  components: { AreaEntregaUI, MesaUI, MeseroUI, TipoPagoUI, Loader },
  data() {
    return {
        tab:0,
        loading: false
    };
  },
  methods: {
    onTabChange(payload) {
      switch (payload.index) {
        case 0:
          this.$refs.mesa.refresh();
          break;
        case 1:
          this.$refs.mesero.refresh();
          break;
        case 2:
          this.$refs.tipopago.refresh();
          break;
        case 3:
          this.$refs.areaentrega.refresh();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.$refs.mesa.refresh();
  }
};
</script>

<style>
</style>

